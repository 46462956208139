.modal-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
}
.modal{
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 18px;
  overflow: hidden;
  margin: 0 8px;
}
.modal .modal-header{
  padding: 10px 12px;
  background-color: #343F56;
  color: white;
  font-size: 20px;
}
.modal .modal-body{
  padding: 10px 12px;
  color: #555;
  font-size: 16px;
}
.modal .modal-footer{
  padding: 10px 12px;
  color: #555;
  font-size: 16px;
}
.modal .modal-footer button{
  background-color: #343F56;
  color: white;
  border-radius: 4px;
}