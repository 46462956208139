@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #343F56;
  color: #fff;
}

.App {
  max-width: 860px;
  margin: 40px auto;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #c23866;
  color: #fff;
}

.card-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 18px;
  gap: 18px;
  margin-top: 20px;
}

.info p{
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 14px;
}
.info a{
  text-decoration: none;
  color: white;
  font-weight: 700;
  text-decoration: underline;
}
.card{
  position: relative;
  cursor: pointer;
}

.card img{
  width: 100%;
  display: block;
  border: 2px solid #fff;
  border-radius: 6px;
}

.card .front{
  position: absolute;
  transform: rotateY(90deg);
  transition: transform 200ms ease-in;
}
.flipped .front{
  transform: rotateY(0deg);
  transition-delay: 200ms;
}

.card .back{
  transform: rotateY(0deg);
  transition: transform 200ms ease-in;
}
.flipped .back{
  transform: rotateY(-90deg);
  transition-delay: 200ms;
}
.modal-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
}
.modal{
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 18px;
  overflow: hidden;
  margin: 0 8px;
}
.modal .modal-header{
  padding: 10px 12px;
  background-color: #343F56;
  color: white;
  font-size: 20px;
}
.modal .modal-body{
  padding: 10px 12px;
  color: #555;
  font-size: 16px;
}
.modal .modal-footer{
  padding: 10px 12px;
  color: #555;
  font-size: 16px;
}
.modal .modal-footer button{
  background-color: #343F56;
  color: white;
  border-radius: 4px;
}
