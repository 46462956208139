.App {
  max-width: 860px;
  margin: 40px auto;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #c23866;
  color: #fff;
}

.card-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 18px;
  margin-top: 20px;
}

.info p{
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 14px;
}
.info a{
  text-decoration: none;
  color: white;
  font-weight: 700;
  text-decoration: underline;
}