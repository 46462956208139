.card{
  position: relative;
  cursor: pointer;
}

.card img{
  width: 100%;
  display: block;
  border: 2px solid #fff;
  border-radius: 6px;
}

.card .front{
  position: absolute;
  transform: rotateY(90deg);
  transition: transform 200ms ease-in;
}
.flipped .front{
  transform: rotateY(0deg);
  transition-delay: 200ms;
}

.card .back{
  transform: rotateY(0deg);
  transition: transform 200ms ease-in;
}
.flipped .back{
  transform: rotateY(-90deg);
  transition-delay: 200ms;
}